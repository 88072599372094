<template>
  <div class="mobile_upload_way">
    <section class="modal_top">
      <div class="close_btn" @click="$emit('close')">
        <img src="@/assets/img/close-black.png" alt="닫기" />
      </div>
      <p>무통장 입금 안내</p>
    </section>
    <section class="modal_bottom">
      <div>
        <p><span>입금 은행 :</span> 우리은행</p>
        <p><span>계좌번호 :</span> 1005-602-207500</p>
        <p><span>예금주명 :</span> (주)넥스트이노베이션</p>
      </div>
      <div>
        <p>
          입금 확인 후 축제 자료를 등록해주시면 됩니다. <br />
          <span>(로그인 > MY스탬프팝 > 축제 자료 등록)</span>
        </p>
      </div>
      <div>
        <p><span>※ 무통장 입금 시 확인해주세요.</span></p>
        <p>
          1. 입금자명과 구매자명(소속/단체명)이 일치하는지 확인해주세요.<br />
          2. 주문금액과 입금금액이 일치하는지 확인해주세요.<br />
          3. 입금 1일 이내로 입금확인이 되지 않는 경우, 고객센터로 문의해주세요.
        </p>
        <p>
          문의사항이 있는 경우 고객센터로 연락주시면 친절히 안내해드리겠습니다.
        </p>
      </div>
      <div class="email_chat">
        <p><span>이메일 : </span>festival@nextinnovation.kr</p>
        <p><span>채팅상담 : </span>우측 하단 문의하기 버튼 클릭</p>
      </div>
      <div class="btn_center">
        <base-button type="primary" @click="$emit('close')">확인</base-button>
      </div>
    </section>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/desktop.scss";
</style>
